import React, { useState } from 'react';
import Layout from '../components/layout';
import './styles.scss';
import WebsiteFullScreenshot from '../components/screenshot';
import { navigate } from '@reach/router';
import DashboardScreen from '../components/dashboard';
import CTA from '../components/cta';
if (!global.window) {
  global.atob = () => {};
}

export default props => {
  const [originalURL, hash] = props['*'] ? props['*'].split('/') : ['', ''];
  const [url, setUrl] = useState(atob(originalURL));
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState(false);

  const handleCaptureOnEnter = e => {
    if (e.key === 'Enter') {
      handleScan();
    }
  };

  const handleChange = e => {
    setUrl(e.target.value);
  };

  const handleScan = async () => {
    setRequesting(true);
    setError(false);
    const res = await fetch(`https://api.hexometer.com/v2/ql`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `{Property{liveScreenshot(address: "${url}"){width height hash}}}`,
      }),
    });
    await res
      .json()
      .then(({ data }) => {
        setRequesting(false);
        navigate(`/${btoa(url)}/${data.Property.liveScreenshot.hash}`);
      })
      .catch(({ error }) => {
        setRequesting(false);
        setError(true);
      });
  };

  return (
    <>
      <Layout
        title={
          'Full Web Page Screen Capture Online - Fullpagescreencapture.com'
        }
        metaName={'description'}
        metaContent={'Generate web page full-length screenshot for free online'}
      >
        <h1 className="subtitle">
          Type the URL of website you want to capture
        </h1>

        <div className="field is-grouped">
          <div className="control is-expanded">
            <input
              className="input is-rounded inputBar"
              type="text"
              placeholder="Input URL to capture"
              value={url}
              onChange={handleChange}
              onKeyPress={handleCaptureOnEnter}
            />
          </div>
          <div className="control" style={{ paddingTop: '10px' }}>
            {requesting ? (
              <div
                className="is-flex"
                style={{ justifyContent: 'center', textAlign: 'center' }}
              >
                <progress
                  className="progress is-small is-primary"
                  max="100"
                  style={{ maxWidth: '600px' }}
                >
                  5%
                </progress>
              </div>
            ) : (
              <div className="buttons is-block btn_capture">
                <button
                  className="button is-primary is-outlined is-medium is-rounded"
                  onClick={handleScan}
                >
                  Capture Now
                </button>
              </div>
            )}
          </div>
        </div>
        {hash && (
          <WebsiteFullScreenshot
            url={url}
            imgUrl={hash}
            width={1920}
            height="auto"
          />
        )}
        {error && (
          <div
            className="is-flex"
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              paddingTop: '20px',
            }}
          >
            <article class="message is-danger" style={{ maxWidth: '300px' }}>
              <div class="message-body">Unable to take a capture</div>
            </article>
          </div>
        )}
        {!hash && <DashboardScreen />}
        <CTA />
      </Layout>
    </>
  );
};
